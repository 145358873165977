<template>
	<div class="homePage">
		<div class="leftMenuBox"  :class="{leftMenuBox:!isCollapse,leftMenuBox64:isCollapse}">
			<leftMenu></leftMenu>
		</div>
		<div :class="{rightContent:!isCollapse,rightContent64:isCollapse}">
			<div class="right_min_width">
				<topNav></topNav>
				<div class="content">
					<router-view/>
				</div>
			</div>
			
			
		</div>
	</div>
</template>

<script>
	import leftMenu from "../components/leftMenu.vue"
	import topNav from "../components/topNav.vue"
	export default{
		components:{
			leftMenu,
			topNav
		},
		data(){
			return{
				isCollapse:false
			}
		},
		mounted() {
			let that = this
			this.eventBus.$on("changeIsCollapse",(isCollapse) => {
				that.isCollapse = isCollapse
				
			})
		}
	}
</script>


<style>
	.homePage{
		/* overflow: hidden; */
		width: 100%;
		height: 100%;
		/* border:1px solid red; */
	}
	.homePage>div{
		float: left;
		height: 100%;
	}
	
	.leftMenuBox>.el-menu{
		height: 100%;
	}
	
	
	.content{
		padding:16px;
		box-sizing: border-box;
	}
</style>
