<template>
	<div>
		<div class="top_nav_box">
			<div class="toogle_icon_box" @click="changeIsCollapse">
				<i class="el-icon-s-fold"  v-if="!isCollapse"></i>
				<i class="el-icon-s-unfold"  v-if="isCollapse"></i>
			</div>
			<div class="login_user_msg_box">
				<div class="name_box">{{t_channel_name}}</div>
				<div class="header_img_box">
					<div class="img_box"><img src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/0/w/100" alt=""></div>

					<!-- <i class="el-icon-caret-bottom"></i> -->
					<el-popover placement="top" width="90" trigger="hover" v-model="popoverShow">
						<div class="popover_box">
							<div @click="goHome">首页</div>
							<div @click="goChangePassword">修改密码</div>
						</div>
						<div class="line_box"></div>
						<div class="popover_box">
							<div @click="logout">注销</div>
						</div>
						<el-button slot="reference"><i class="el-icon-caret-bottom"></i></el-button>
						<!-- <el-button slot="reference">click 激活</el-button> -->
					</el-popover>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				popoverShow:false,
				isCollapse:false,
				t_channel_name:''
			};
		},
		mounted() {
			this.t_channel_name = localStorage.getItem("t_channel_name");
		},
		methods:{
			goHome(){
				console.log("goChangePassword")
				this.$router.push('/home/welcome');
				this.togglePopoverShow()
			},
			goChangePassword(){
				console.log("goChangePassword")
				this.$router.push('/home/changePassword');
				this.togglePopoverShow()
			},
			logout(){
				let that = this
				let param = {
					url: "userLogout",
					data: null,
					isLoading:true
				}
				this.api(param).then(res => {
					console.log("res", res)
					if(res.status == 0){
						that.$router.push('/login');
					}
				})
			},
			togglePopoverShow(){
				this.popoverShow = false
			},
			changeIsCollapse(){
				this.isCollapse = !this.isCollapse
				this.eventBus.$emit("changeIsCollapse",this.isCollapse)
			}
		}
	};
</script>

<style>
	.top_nav_box {
		width: 100%;
		height: 50px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		padding: 0 16px;
		box-sizing: border-box;
	}

	.toogle_icon_box {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.el-icon-s-fold,.el-icon-s-unfold {
		font-size: 30px;
		color: #333333;
	}

	.login_user_msg_box {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login_user_msg_box>.name_box {
		font-size: 14px;
	}

	.header_img_box {
		display: flex;
		justify-content: center;
		align-items: flex-end;
	}

	.header_img_box>.img_box {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-left: 10px;
		margin-right: 10px;
	}

	.header_img_box>.img_box>img {
		width: 100%;
		height: 100%;
	}

	.el-icon-caret-bottom {
		font-size: 10px;
		color: #333333;
	}

	.header_img_box .el-button{
		padding:0 !important;
		border:none !important;
	}
	.popover_box,.popover_box>div{
		width: 100%;
	}
	.popover_box>div{
		height: 30px;
		line-height: 30px;
		font-size: 14px;
		padding: 0 !important;
		text-align: center;
	}
	.popover_box>div:hover{
		background-color: #ecf5ff;
		color: #66b1ff;
		cursor: pointer;
	}
	.top_nav_box .line_box{
		width: 100%;
		height: 1px;
		background-color: #DCDFE6;
		margin-top: 5px;
		margin-bottom: 5px;
	}
	.el-popover{
		padding:5px 0 !important;
		min-width: 90px !important;
	}
	
</style>
